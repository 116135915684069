import superagent from 'superagent';

const FIVE_MEGABYTES = 5 * 1024 * 1024;

$(() => {
  const $form = $('#file-upload-form');
  if (!$form) return;

  const beforeUnloadListener = event => {
    //This message does not matter, you can't customize it now. but you have to return a message
    return (event.returnValue =
      'Upload changes will be lost if you navigate away from this page.');
  };

  $form.on('submit', async e => {
    e.preventDefault();

    window.addEventListener('beforeunload', beforeUnloadListener);

    const description = $('#description').val();
    const datasets = $('#datasets').val();
    const formUrl = $form.data().url;
    const submit = $('button[type=submit]');
    const files = Array.from(
      $<HTMLInputElement>('input[type=file]')[0]?.files ?? []
    );

    submit.prop('disabled', true);

    if (!files || !files.length) {
      alert('Please choose files to upload.');
      submit.prop('disabled', false);
      return;
    }

    // Part one, upload the description, dataset, and files' names and sizes to
    // create a new FileUpload and get back the urls to upload the actual files
    let completeUrl: string,
      detailUrl: string,
      uploads: CreateResponse['uploads'];
    try {
      const { body } = await createUpload(formUrl, {
        description,
        datasets,
        files: files.map(f => ({ name: f.name, size: f.size }))
      });
      detailUrl = body.detail_url;
      completeUrl = body.complete_url;
      uploads = body.uploads;
    } catch (e) {
      // @ts-ignore
      $('#fileUploadModalFailure').modal();
      console.error(e);
      throw e;
    }

    const numberOfParts = Object.values(uploads).reduce(
      (acc, upload) => acc + upload.urls.length,
      0
    );

    startProgress(numberOfParts);

    // Part Two use the presigned URLs from AWS to upload the actual file. We
    // get the etag and part number from AWS responses
    let filesParts;
    try {
      filesParts = await Promise.all(
        files.map(async file => {
          const { urls, upload_id: uploadId } = uploads[file.name];
          const parts = await Promise.all(
            urls.map(async (url, i) => uploadPart(url, file, i))
          );

          return { parts, upload_id: uploadId, name: file.name };
        })
      );
    } catch (e) {
      console.error(e);
      await onError(detailUrl);
      throw e;
    }

    // Part Three, complete the file upload on our server, setting its status
    // to complete
    try {
      await superagent
        .post(completeUrl)
        .send({ files: filesParts })
        .set('X-CSRFToken', getCookie('csrftoken'))
        .then();
    } catch (e) {
      console.error(e);
      await onError(detailUrl);
      throw e;
    }

    $('#description').val('');
    $('#dataset').val('');
    $('input[type=file]').val('');
    // @ts-ignore
    $('#fileUploadModalSuccess').modal();
    window.removeEventListener('beforeunload', beforeUnloadListener);
    submit.prop('disabled', false);
  });
});

// This is a handy function taken from Django docs to get
// cookie values by name. It is primarily used to grab the
// CSRF token and inject it into the POST payload.
function getCookie(name: string) {
  let cookieValue: any = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let index = 0; index < cookies.length; index++) {
      const cookie = cookies[index].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

/** Upload a slice of a file to a presigned S3 URL */
const uploadPart = async (url: string, file: File, i: number) => {
  let data: any;
  // TODO: Check Math?
  if (file.size < (i + 1) * FIVE_MEGABYTES) {
    data = file.slice(i * FIVE_MEGABYTES);
  } else {
    data = file.slice(i * FIVE_MEGABYTES, i * FIVE_MEGABYTES + FIVE_MEGABYTES);
  }
  const res = await superagent
    .put(url)
    .send(data)
    .then();
  if (!res.ok) {
    throw res.error;
  }
  incrementProgress();
  return { ETag: JSON.parse(res.header['etag']), PartNumber: i + 1 };
};

type CreateResponse = {
  uploads: {
    [filename: string]: {
      urls: string[];
      upload_id: string;
    };
  };
  complete_url: string;
  detail_url: string;
};

const createUpload = async (url, body) =>
  await superagent
    .post(url)
    .withCredentials()
    .send(body)
    .set('X-CSRFToken', getCookie('csrftoken'))
    .then<{ body: CreateResponse }>();

const onError = detailUrl => {
  // @ts-ignore
  $('#fileUploadModalFailure').modal();
  return superagent
    .post(detailUrl)
    .send({ status: 'file_upload_status_error' })
    .set('X-CSRFToken', getCookie('csrftoken'))
    .then();
};

const startProgress = amount => {
  const $submit = $('button[type=submit]');
  const progress = document.createElement('progress');
  progress.max = amount;
  progress.value = 0;
  $submit.parent().append(progress);
};

const incrementProgress = () => {
  const progress = document.querySelector('progress');
  if (!progress) return;
  progress.value = progress.value + 1;
  if (progress.value === progress.max) {
    progress.remove();
  }
};
