export default (appId, user = null) => {
  if (appId && user.id) {
    window.intercomSettings = {
      app_id: appId
    };
    if (user) {
      window.intercomSettings.name = `${user.first} ${user.last}`;
      window.intercomSettings.user_hash = user.intercom_user_hash;
      window.intercomSettings.email = user.email;
      window.intercomSettings.company = {
        id: user.org_id,
        name: user.org_name
      };
      window.intercomSettings.custom_launcher_selector = '.intercom-launch';
    }
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = () => {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appId}`;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
};
