import React from 'react';
import {
  Navbar as BSNavbar,
  MenuItem,
  Nav,
  NavDropdown,
  NavItem
} from 'react-bootstrap';
// @ts-ignore
import strataLogo from '../../static/img/logos/strata-logo-text.svg';

const config: any = window['CONFIGURATION'];

export const Navbar = () => {
  const {
    id,
    first,
    last,
    has_blackbird,
    has_canvas,
    has_gallery,
    has_launch_pathway,
    has_physician_loyalty,
    has_physician_pathway,
    has_physician_patterns,
    has_storyboards,
    has_focus_origin_out_migration,
    has_focus_referrals,
    has_reimbursement_intelligence,
    is_staff
  } = config.USER;

  const productDropdownItem = (
    linkPath: string,
    linkText: string,
    indent = true
  ) => (
    <MenuItem href={linkPath} className="nav-dropdown-item">
      {indent ? (
        <div className="nav-dropdown-indented">{linkText}</div>
      ) : (
        <div>{linkText}</div>
      )}
    </MenuItem>
  );

  const userDropdownItem = (
    linkPath: string,
    linkText: string,
    icon: string,
    divider: boolean
  ) => (
    <MenuItem href={linkPath} className="nav-dropdown-item m-0">
      <div className="divided-left">
        <i className={`fa fa-${icon}`}></i> {linkText}
      </div>
      {divider && <li className="divider"></li>}
    </MenuItem>
  );

  return (
    <BSNavbar className="navbar nav-collapse-fix navbar-fixed-top nav-level-one nav-global">
      <BSNavbar.Brand>
        <a href="/">
          <div className="logo-container">
            <img
              className="strata-logo__nav"
              src={strataLogo}
              alt="Strata logo"
            />
            <span className="strata-logo__text">| Market Solutions</span>
          </div>
        </a>
      </BSNavbar.Brand>
      <BSNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BSNavbar.Collapse id="basic-navbar-nav">
        {id && (
          <Nav className="nav navbar-right nav-align" id="main-nav">
            {(has_blackbird || has_canvas) && (
              <NavDropdown
                id="nav-dropdown-market-opp-analysis"
                title={<>Market Opportunity Analysis</>}
              >
                {has_canvas && (
                  <>
                    {productDropdownItem(
                      '/reports/home',
                      'Growth Reports',
                      false
                    )}
                    {productDropdownItem('/reports/start', 'Create Report')}
                    {productDropdownItem('/reports/home', 'Reports')}
                  </>
                )}
                {has_blackbird && (
                  <>
                    {productDropdownItem('/blackbird', 'Market Query', false)}
                    {productDropdownItem('/blackbird/choose', 'New Query')}
                    {productDropdownItem('/blackbird', 'Dashboard')}
                  </>
                )}
              </NavDropdown>
            )}
            {(has_launch_pathway ||
              has_physician_pathway ||
              has_focus_origin_out_migration ||
              has_focus_referrals ||
              has_reimbursement_intelligence) && (
              <NavDropdown
                id="nav-dropdown-market-opp-vis"
                title={<>Market Opportunity Visualization</>}
              >
                {has_launch_pathway && (
                  <>
                    {productDropdownItem(
                      '/pathways/launch',
                      'Market Share & Trending',
                      false
                    )}
                  </>
                )}
                {has_storyboards && (
                  <>
                    {productDropdownItem(
                      '/pathways/storyboards',
                      'Presentation'
                    )}
                  </>
                )}
                {has_focus_origin_out_migration && (
                  <>
                    {productDropdownItem(
                      '/focus/origin-out-migration',
                      'Patient Origin & Out-Migration',
                      false
                    )}
                  </>
                )}
                {has_focus_referrals && (
                  <>
                    {productDropdownItem(
                      '/focus/referrals',
                      'Referral Patterns',
                      false
                    )}
                  </>
                )}
                {has_physician_pathway && (
                  <>
                    {productDropdownItem(
                      '/pathways/physician/patterns',
                      'Physician Pathway',
                      false
                    )}
                  </>
                )}
                {has_physician_patterns && (
                  <>
                    {productDropdownItem(
                      '/pathways/physician/patterns',
                      'Physician Patterns'
                    )}
                  </>
                )}
                {has_physician_loyalty && (
                  <>
                    {productDropdownItem(
                      '/pathways/physician/loyalty',
                      'Physician Loyalty'
                    )}
                  </>
                )}
              </NavDropdown>
            )}
            {has_reimbursement_intelligence && (
              <NavItem href="/reimbursement/reimbursement_reports">
                Market Reimbursement Intelligence
              </NavItem>
            )}
            {has_gallery && <NavItem href="/gallery">Gallery</NavItem>}
            <NavDropdown
              id="nav-dropdown-user"
              title={
                <>
                  <i className="fa fa-user" />{' '}
                  <span className="nav-title ">
                    {first} {last}
                  </span>
                </>
              }
              className="last"
            >
              {userDropdownItem('/', 'Home', 'home', true)}
              {userDropdownItem(
                '/account',
                'Account Headquarters',
                'cogs',
                true
              )}
              {is_staff && (
                <>
                  {userDropdownItem(
                    '/backoffice',
                    'Back Office',
                    'users',
                    false
                  )}
                  {userDropdownItem('/admin', 'Admin', 'cogs', true)}
                </>
              )}
              {userDropdownItem('/account/logout', 'Logout', 'sign-out', false)}
            </NavDropdown>
          </Nav>
        )}
      </BSNavbar.Collapse>
    </BSNavbar>
  );
};
