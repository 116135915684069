import * as Sentry from '@sentry/browser';

export default (dsn, user = null, environment = null) => {
  if (dsn) {
    Sentry.init({
      dsn: dsn,
      debug: environment === 'prod',
      blacklistUrls: [/intercomcdn/, /cdn\.pendo\.io/],
      environment: environment,
      release: process.env.GIT_SHA
    });
    if (user) {
      Sentry.getCurrentScope().setUser({ id: user.id, email: user.email });
    }
  }
};
