import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ErrorLoggingInit from './errorLogging';
import IntercomInit from './intercom';
import './fileUploads';
import './navGlobal';

ErrorLoggingInit(
  window.CONFIGURATION.SENTRY_DSN,
  window.CONFIGURATION.USER,
  window.CONFIGURATION.ENVIRONMENT
);
IntercomInit(window.CONFIGURATION.INTERCOM_ID, window.CONFIGURATION.USER);

if (process.env.NODE_ENV !== 'production') {
  document.querySelector('body').classList.add('DEV');
}
